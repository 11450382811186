import Home from "./pages/Home";

import Modal from 'react-modal';

Modal.setAppElement('#root');

function App() {
  return (
    <Home />
  );
}

export default App;
