import React from 'react'
const Contact = () => {
    return (
        <div className='w-full  mt-10 bg-sky-200' id='Contact'>
            <p className='text-center text-5xl py-10'>Contact</p>
            <div className='custom_container flex lg:p-10 p-2 flex-wrap  h-full '>
                <div className='lg:basis-[50%] '>
                    <div className='flex items-center mb-3 text-xl'>
                        <div className='w-2 h-8 bg-yellow-200 mr-2'></div>
                        <p className='uppercase text-3xl'>Address</p>
                    </div>
                    <div className='text-xl'>
                        <p>Property No: 205,</p>
                        <p>Land DM No: 117-499,</p>
                        <p>Building Name: Abdulla Building 9
                            <br></br>  Al Mararr, Deira, Dubai, UAE.</p>
                        <u className='block'>Phone: <a href='tel: +971582663157'> +971582663157</a> </u>
                        <u>Email: <a href='mailto: info@demogroup.net'> info@demogroup.net</a> </u>
                    </div>

                </div>
                <div className='lg:basis-[50%] mt-10 lg:mt-0'>

                    <div class="flex gap-4 flex-wrap lg:flex-nowrap justify-between">
                        <input className='border-gray-400 rounded-md w-full border-2 py-2 px-2' placeholder='name' />
                        <input className='border-gray-400 w-full rounded-md border-2 py-2 px-2' placeholder='Email' />
                    </div>
                    <div class="flex gap-4 justify-between flex-wrap lg:flex-nowrap">
                        <input className='mt-4 border-gray-400 rounded-md w-full border-2 py-2 px-2' placeholder='Phone' />
                        <input className='mt-4 border-gray-400 rounded-md w-full border-2 py-2 px-2' placeholder='City' />

                    </div>
                    <textarea rows={5} className='mt-4 border-gray-400 w-full rounded-md border-2 py-2 px-2' placeholder='Message' />

                    <button className='bg-sky-300 mt-5 p-4 '>Sent Mesage</button>
                </div>
            </div>
        </div>
    )
}

export default Contact