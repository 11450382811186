import React from 'react'

const ServiceItem = ({ Icon, title, content }) => {
    return (
        <div className='flex basis-1/2 p-5'>
            <Icon size={100} />
            <div>
                <p className='ml-4 text-2xl'>{title}</p>
                <p className='ml-4'>{content}</p>
            </div>

        </div>
    )
}

export default ServiceItem