import React from 'react'
import ServiceItem from './component/ServiceItem'
import serviceData from '../../rawData/service'
const Service = () => {
    return (
        <div className='custom_container' id='Service'>
            <div className='py-16 flex basis-1/3 lg:flex-nowrap flex-wrap '>
                <div className='row-span-2'>
                    <div className='flex mb-2'>
                        <div className='w-2 h-15 mr-2 bg-yellow-200'></div>
                        <p className='text-xl'>What we Do</p>
                    </div>
                    <p className='text-5xl w-[400px]'>Safe & Reliable Cargo Solutions</p>
                </div>


                <div className='flex flex-wrap mt-8 lg:mt-0'>
                    {
                        serviceData.map(service =>
                            <ServiceItem
                                key={service.id}
                                Icon={service.Icon}
                                title={service.title}
                                content={service.content}
                            />)
                    }
                </div>
            </div>
        </div>
    )
}

export default Service