import React from 'react'
import shipImage from '../../assets/shipImage.jpg'

import { BiSupport } from 'react-icons/bi';
import { AiOutlineGlobal } from 'react-icons/ai';
import { FiPackage, FiClock } from 'react-icons/fi';
import { FaShip, FaTruckMoving } from 'react-icons/fa';

const ShipmentService = () => {
    return (
        <div className='custom_container' id="About">
            <div className='flex justify-between flex-wrap my-16 gap-10'>
                <div className='lg:basis-[45%] '>
                    <div className='flex items-center mb-3'>
                        <div className='w-2 h-4 bg-yellow-200 mr-2'></div>
                        <p>Why Choice Us</p>
                    </div>
                    <p className='text-4xl'>We create opportunity to reach potential</p>
                    <p className='text-justify mt-3'>
                        Our vast network of partners and agents enables us to offer comprehensive coverage and reach virtually any destination worldwide. Whether you're shipping locally or internationally, we have the connections and resources to ensure your goods reach their destination efficiently and on time.
                    </p>

                    <div className='flex flex-wrap justify-between'>
                        <div className='flex pt-6 items-center '>
                            <div className='h-10 items-center flex justify-center w-10 bg-yellow-400 rounded-full mr-5'>
                                <FaShip color='black' size={20} />
                            </div>
                            <p className='text-xl'>Ship Everyware</p>
                        </div>
                        <div className='flex pt-6 items-center '>
                            <div className='h-10 items-center flex justify-center w-10 bg-yellow-400 rounded-full mr-5'>
                                <FiPackage color='black' size={20} />
                            </div>
                            <p className='text-xl'>Safe Package</p>
                        </div>
                        <div className='flex pt-6 items-center '>
                            <div className='h-10 items-center flex justify-center w-10 bg-yellow-400 rounded-full mr-5'>
                                <AiOutlineGlobal color='black' size={20} />
                            </div>
                            <p className='text-xl'>Global Tracking</p>
                        </div>
                        <div className='flex pt-6 items-center '>
                            <div className='h-10 items-center flex justify-center w-10 bg-yellow-400 rounded-full mr-5'>
                                <FaTruckMoving color='black' size={20} />
                            </div>
                            <p className='text-xl'>Transparant      </p>
                        </div>
                        <div className='flex pt-6 items-center '>
                            <div className='h-10 items-center flex justify-center w-10 bg-yellow-400 rounded-full mr-5'>
                                <FiClock color='black' size={20} />
                            </div>
                            <p className='text-xl'>In Time Delivery</p>
                        </div>
                        <div className='flex pt-6 items-center '>
                            <div className='h-10 items-center flex justify-center w-10 bg-yellow-400 rounded-full mr-5'>
                                <BiSupport color='black' size={20} />
                            </div>
                            <p className='text-xl'>24/7 Support</p>
                        </div>
                    </div>

                </div>
                <div className='lg:basis-[50%]'>
                    <img src={shipImage} className='h-full w-full rounded-md' alt='about' />
                </div>
            </div>
        </div>
    )
}

export default ShipmentService