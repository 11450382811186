import React from 'react'

const Footer = () => {
    return (
        <div className='w-full lg:h-96 p-5 bg-black '>
            <div className='custom_container py-5 lg:grid lg:grid-cols-3 justify-items-center'>
                <div className='text-white '>
                    <div className='flex items-center mb-3 text-xl '>
                        <div className='w-2 h-4 bg-yellow-200 mr-2'></div>
                        <p className='uppercase text-3xl'>Address</p>
                    </div>
                    <div className='text-xl text-white'>
                        <p>Property No: 205,</p>
                        <p>Land DM No: 117-499,</p>
                        <p>Building Name: Abdulla Building 9
                            <br></br> Al Mararr, Deira, Dubai, UAE.</p>
                        <u className='block'>Phone: <a href='tel: +971582663157'> +971582663157</a> </u>
                        <u>Email: <a href='mailto: info@demogroup.net'> info@demogroup.net</a> </u>
                    </div>

                </div>
                {/* menu link */}
                <div className='text-white mt-10 lg:mt-0'>
                    <p className='cursor-pointer   bold'> <a href="#Home"> Home</a></p>
                    <p className='cursor-pointer mt-2 bold'><a href="#About"> About</a></p>
                    <p className='cursor-pointer mt-2 bold'> <a href="#Service"> Service</a></p>
                    <p className='cursor-pointer mt-2 bold'> <a href="#Blog">Blog</a></p>
                    <p className='cursor-pointer mt-2 bold'><a href="#Contact">Contact</a></p>
                </div>
                <div className='text-white mt-10 lg:mt-0'>
                    <input className='p-3 border-gray-400  w-full border-2 ' placeholder='Email' />
                    <button className='py-2 px-5 bg-sky-300 text-black hover:bg-sky-500 mt-2'>Sent </button>
                </div>
            </div>
        </div>
    )
}

export default Footer