import React, { useState } from 'react'
import mainContent from '../../rawData/mainContentData'
import BlogItem from './Component/BlogItem'


import Modal from 'react-modal';

const Blog = () => {

    const [modalIsOpen, setIsOpen] = useState(false);
    const [contentId, setContentId] = useState();

    const random = Math.ceil(Math.random() * (mainContent.length - 6))
    const sliceData = mainContent.slice(random, (random + 3))

    const filterData = mainContent.find(filter => filter.id === contentId)

    const customStyles = {
        content: {
            position: 'absolute',
            width: '80%',
            height: '70%',
            top: '50%',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            left: '50%',

        },
    };


    function openModal(id) {
        setIsOpen(true);
        setContentId(id)
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className='relative lg:mt-[-200px] custom_container' id='Blog'>
            <p className='text-center text-5xl my-20'>Our Latest News</p>
            <div className='flex justify-between W-full gap-4 flex-wrap'>
                {
                    sliceData.map(blog =>
                        <BlogItem
                            key={blog.id}
                            title={blog.title}
                            content={blog.content}
                            img={blog.img}
                            openModal={openModal}
                            id={blog.id}
                        />)
                }
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
            >
                <div className='w-10 h-10 bg-sky-500 rounded-full  fixed right-0 top-0 text-white flex items-center justify-center text-3xl cursor-pointer' onClick={closeModal}>X</div>
                <div className='text-3xl uppercase mb-3' dangerouslySetInnerHTML={{ __html: `${filterData && filterData.title}` }}></div>
                <div dangerouslySetInnerHTML={{ __html: `${filterData && filterData.content}` }}></div>
            </Modal>
        </div>
    )
}

export default Blog