import React from 'react'

const NavtopItem = ({ Icon, title, content }) => {
    return (
        <div className='flex items-center px-4'>
            <div className='h-8 w-8 rounded-full bg-blue-300 flex justify-center items-center'>
                {/* <LuClock8 color='white' size={20} /> */}
                <Icon color={'white'} size={20} />
            </div>
            <p className='pl-2'>
                <span className='text-gray-500'> {title} </span><br></br>
                {content}
            </p>
        </div>
    )
}

export default NavtopItem