import React from 'react'
import demoLogo from '../../assets/demo_logo.png'
import navTopItem from '../../rawData/navTopItem'
import NavtopItem from './components/NavtopItem'


const NavTop = () => {
    return (
        <div className='flex justify-center lg:justify-between  items-center  flex-wrap  custom_container' id='Home'>
            <div className='h-18 w-60'>
                <img src={demoLogo} className='w-full h-full flex-shrink-0' alt='Demotrans Logo' />
            </div>

            <div className='hidden lg:block'>
                <div className='flex list-none justify-between col-span-2 '>
                    {
                        navTopItem.map(item =>
                            <NavtopItem
                                key={item.id}
                                Icon={item.Icon}
                                title={item.title}
                                content={item.content}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default NavTop