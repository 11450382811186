
import { RiShipFill } from 'react-icons/ri';
import { TbBuildingWarehouse } from 'react-icons/tb';
import { FaPlaneDeparture } from 'react-icons/fa';
import { MdOutlineLocalShipping } from 'react-icons/md';



const serviceData = [
    {
        id: 1,
        Icon: RiShipFill,
        title: 'Sea Transport Services',
        content: ' We offers a comprehensive range of services to handle your entire shipping process.',
    },
    {
        id: 2,
        Icon: TbBuildingWarehouse,
        title: 'Warehousing Services',
        content: 'Our warehousing services are designed to accommodate your changing needs.',
    },
    {
        id: 3,
        Icon: FaPlaneDeparture,
        title: 'Air Fright Services',
        content: 'Our air freight services cover a vast network of destinations worldwide. ',
    },
    {
        id: 4,
        Icon: MdOutlineLocalShipping,
        title: 'Local Shipping Services',
        content: 'We have in-depth knowledge of the local landscape, including roads, routes, and regulations.',
    },
]

export default serviceData