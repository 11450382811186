import React from 'react'
import container from '../../assets/container.jpg'
import product from '../../assets/product.png'
import { BiMoneyWithdraw } from 'react-icons/bi';
import { FiClock } from 'react-icons/fi';

const Promotion = () => {
    return (
        <div className=''>
            <div className='h-96 relative'>
                <img src={container} className='h-full w-full object-cover' alt='ship' />
                <div className='absolute h-full w-full top-0  bg-gradient-to-t from-black   '></div>
            </div>


            <div className='relative lg:top-[-200px] w-full rounded-md bg-white  custom_container  shadow-2xl'>
                <div className='flex lg:p-20 p-5 justify-between flex-wrap'>
                    <div className='lg:basis-[50%] '>
                        <div className='flex items-center mb-3'>
                            <div className='w-2 h-4 bg-yellow-200 mr-2'></div>
                            <p>Why Choice Us</p>
                        </div>
                        <p className='text-4xl'>We provide full range global logistics solution</p>
                        <p className='text-justify mt-3'>
                            We take an integrated approach to global logistics, seamlessly connecting all stages of your supply chain. From sourcing and procurement to transportation, warehousing, and distribution, our comprehensive solutions ensure a seamless flow of goods from origin to destination.
                        </p>
                        <div className='flex pt-6 items-center '>
                            <div className='h-10 items-center flex justify-center w-10 bg-yellow-400 rounded-full mr-5'>
                                <FiClock color='black' size={20} />
                            </div>
                            <p className='text-xl'>Delivery on Time</p>
                        </div>
                        <div className='flex pt-6 items-center '>
                            <div className='h-10 items-center flex justify-center w-10 bg-yellow-400 rounded-full mr-5'>
                                <BiMoneyWithdraw color='black' size={20} />
                            </div>
                            <p className='text-xl'>Optimized Travel Cost</p>
                        </div>
                    </div>

                    <div className='lg:basis-[50%] px-5'>
                        <img src={product} className='w-full h-full' alt='service' />
                    </div>

                </div>
            </div>


        </div>

    )
}

export default Promotion