import React from 'react'
import NavTop from '../components/NavTop/NavTop'
import Hetosection from '../components/HeroSection/Hetosection'
import Navbar from '../components/Navbar/Navbar'
import Service from '../components/Service/Service'
import Promotion from '../components/Promotion/Promotion'
import Blog from '../components/Blog/Blog'
import About from '../components/About/About'
import Contact from '../components/Contact/Contact'
import ShipmentService from '../components/ShipmentService/ShipmentService'
import Footer from '../components/Footer/Footer'

const Home = () => {
    return (
        <div>
            <NavTop />
            <Navbar />
            <Hetosection />
            <Service />
            <About />
            <Promotion />
            <Blog />
            <Contact />
            <ShipmentService />
            <Footer />
        </div>
    )
}

export default Home