import React from 'react'
import about from '../../assets/about.jpg'

const About = () => {
    return (
        <div className='custom_container' id="About">
            <div className='flex justify-between flex-wrap my-16 gap-10'>
                <div className='lg:basis-[50%]'>
                    <img src={about} className='h-full w-full rounded-md' alt='about' />
                </div>
                <div className='lg:basis-[45%]' >
                    <div className='flex text-3xl items-center lg:mb-5'>
                        <div className='w-2 h-8 bg-yellow-400 lg:mr-5 mr-3'></div>
                        <p >Welcome To Demotrans </p>
                    </div>
                    <p className='text-lg text-justify'>The world of Demotrans is always open for you all. The entirety of the stakeholders creates the feeling of a single entity. Thence we offer the pragmatic comfort for the people of the business domain. The highest feasible version of freight forwarding invites you here. State-of-the-art services await you to entertain. And you can release your insomniac tensions relying on us. In fact, we love to take care of the customers.</p>
                </div>
            </div>
        </div>
    )
}

export default About