import React from 'react'
import hero_img from '../../assets/hero_img.png'

const Hetosection = () => {
    return (
        <div className='relative h-full w-full'>
            <img src={hero_img} className='w-full h-full' alt='shipping' />

            <div className='absolute hidden md:block  custom_container  md:top-[25%] lg:top-[35%]'>
                <div>
                    <div className='flex'>
                        <div className='w-2 h-15 mr-2 bg-yellow-200'> </div>
                        <p className='text-white bg-blue-500 custom_bg_color inline-block p-1'>Logistics & Supply Chain Solutions</p>
                    </div>
                    <p className='w-[550px]  text-white  font-bold text-6xl'>Your Gateway to any Destination in the World</p>
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default Hetosection