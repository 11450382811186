import { LuClock8 } from 'react-icons/lu';
import { HiOutlineMail } from 'react-icons/hi';
import { BiPhoneCall } from 'react-icons/bi';

const navTopItem = [
    {
        id: 1,
        Icon: LuClock8,
        title: 'Saturday is Closed',
        content: 'Sun - Fri 9.00 - 18.00',
    },
    {
        id: 2,
        Icon: HiOutlineMail,
        title: 'Email',
        content: 'info@demogroup.net',
    },
    {
        id: 3,
        Icon: BiPhoneCall,
        title: 'Cell ',
        content: '+971582663157',
    },
]



export default navTopItem;