import React from 'react'

const BlogItem = ({ title, content, img, openModal, id }) => {
    return (
        <div class="lg:max-w-sm rounded overflow-hidden shadow-lg cursor-pointer  hover:mt-[-10px] transition-all duration-200" onClick={() => openModal(id)}>
            <img class="w-full" src={img} alt="Sunset in the mountains" />
            <div class=" py-4">
                <div class="font-bold text-xl mb-2">{title}</div>
                <p class="text-gray-700 text-justify text-base">{content.slice(0, 200)}</p>
            </div>
        </div>

    )
}

export default BlogItem