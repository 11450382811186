import React from 'react'

import { TiSocialLinkedinCircular } from 'react-icons/ti';
import { SiYoutubemusic } from 'react-icons/si';


const Navbar = () => {
    return (
        <div className='w-full bg-[#0496D1] sticky top-0 z-10'>

            <div className="navbar  flex-row-reverse ">
                <div className="navbar flex justify-between items-center w-full">
                    <div className="dropdown">
                        <label tabIndex={0} className="btn btn-ghost lg:hidden text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                        </label>
                        <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                            <li> <a href="#Home"> Home</a></li>
                            <li><a href="#Service"> Service</a></li>
                            <li><a href="#About"> About</a></li>
                            <li><a href="#Blog">Blog</a></li>
                            <li><a href="#Contact">Contact</a></li>
                        </ul>
                    </div>
                    <div className='flex items-center lg:hidden'>
                        <TiSocialLinkedinCircular size={32} color='white' className='m-5' />
                        <SiYoutubemusic size={24} color='white' />
                    </div>
                </div>

                <div className='absolute z-20 w-full h-12 custom_bg_color hidden lg:block'>
                    <div className='custom_container text-white opacity-100 flex justify-between h-12 items-center'>
                        <div className='flex justify-between basis-1/2'>
                            <p className='cursor-pointer hover:text-black bold'> <a href="#Home"> Home</a></p>
                            <p className='cursor-pointer hover:text-black bold'> <a href="#Service"> Service</a></p>
                            <p className='cursor-pointer hover:text-black bold'><a href="#About"> About</a></p>
                            <p className='cursor-pointer hover:text-black bold'> <a href="#Blog">Blog</a></p>
                            <p className='cursor-pointer hover:text-black bold'><a href="#Contact">Contact</a></p>
                        </div>
                        <div className='flex items-center '>
                            <a target='_blank' rel="noreferrer" href='https://www.linkedin.com/company/demotransbangladesh/'>
                                <TiSocialLinkedinCircular size={32} color='white' className='m-5' />
                            </a>
                            <a target='_blank' rel="noreferrer" href='https://www.youtube.com/watch?v=ecVUDrTkXX4'>
                                <SiYoutubemusic size={24} color='white' />
                            </a>
                        </div>
                    </div>

                </div>




            </div>
        </div >
    )
}

export default Navbar